<template>
  <div>
    <b-container class="mt-5 mb-5">
      <b-button variant="outline-primary" @click="deleteMember()" class="float-right">
        Verwijder teamlid
      </b-button>
      <h1 class="fs-4 font-weight-bold mb-4">Teamlid wijzigen</h1>

      <ValidationObserver ref="form" slim>
        <b-card class="mb-3">
          <b-row>
            <b-col md="6">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="mb-3">
                  <label class="required">Voornaam</label>
                  <b-form-input v-model="form.firstname" />
                  <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </b-col>

            <b-col md="6">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="mb-3">
                  <label class="required">Achternaam</label>
                  <b-form-input v-model="form.lastname "/>
                  <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                </div>
              </ValidationProvider>
            </b-col>
          </b-row>

          <div class="mb-3">
            <font-awesome-icon @click="toggleInfo()" icon="fa-solid fa-info-circle" class="text-primary cursor-pointer mr-2"/>
            <label class="required">Rol</label>
            <b-form-select v-model="form.role" :options="roleOptions" />
          </div>

          <b-alert variant="secondary" show v-if="showExplainRoles">
            <h5>Uitleg rollen</h5>
            <ul class="list-unstyled">
              <li><strong>Geen:</strong> Kan inloggen, maar heeft (nog) geen verdere toegang.</li>
              <li><strong>Administrator:</strong> Heeft volledig beheer over het systeem.</li>
              <li><strong>Manager:</strong> Heeft volledig beheer over één of meerdere locaties.</li>
              <li><strong>Servicemedewerker:</strong> Heeft toegang tot klantgegevens en tickets voor één of meerdere locaties, met de mogelijkheid deze te beheren.</li>
              <li><strong>Scan- en kassamedewerker:</strong> Kan voor één of meerdere locaties tickets controleren en/of scannen.</li>
            </ul>
          </b-alert>

          <div class="mb-3" v-if="form.role == 'manager' || form.role == 'service' || form.role == 'scanner'">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="mb-3">
                <label class="required">Locaties</label>
                <b-form-multiselect v-model="form.locations" :options="locationsOptions" @change="filterEvents()" />
                <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
              </div>
            </ValidationProvider>

            <div class="mb-3">
              <label class="mb-0">Events</label>
              <p class="text-muted mb-2">Beperk toegang tot specifieke evenementen binnen de locatie.</p>
              <b-form-multiselect v-model="form.events" :options="eventsOptions" />
            </div>
          </div>

          <ValidationProvider rules="required|email|unique" v-slot="{ errors }">
            <div class="mb-3">
              <label class="required">E-mail</label>
              <b-form-input v-model="form.username" />
              <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
            </div>
          </ValidationProvider>
        </b-card>

        <b-card class="mb-3">
          <h3 class="fs-5 mb-2">Wachtwoord wijzigen</h3>
          <div class="mb-3">
            <label>Wachtwoord</label>
            <b-form-input v-model="form.password" type="text" />
          </div>
          <div class="mb-3">
            <b-form-checkbox v-model="form.is_send_email" class="pl-1">
              Ja, stuur een e-mail met het wachtwoord.
            </b-form-checkbox>
          </div>
        </b-card>
      </ValidationObserver>

      <div class="clearfix">
        <b-button @click="saveMember" variant="primary" class="float-right">
          Opslaan
          <font-awesome-icon icon="fa-solid fa-chevron-right" />
        </b-button>
      </div>
      
    </b-container>
  </div>
</template>

<script>
  import { mapActions } from 'pinia'
  import { useShopStore } from '@/stores/shop'
  import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
  import BFormMultiselect from '@/components/BFormMultiselect'

  export default {
      metaInfo: {
          title: 'Teamlid wijzigen',
      },
      components: {
        ValidationObserver,
        ValidationProvider,
        BFormMultiselect,
      },
      data() {
          return {
            showExplainRoles: false,
            form: {
              id: null,
              firstname: null,
              lastname: null,
              role: 'none',
              locations: [],
              events: [],
              username: null,
              password: null,
              is_send_email: true,
            },
            roleOptions: [
              { text: 'Geen', value: 'none' },
              { text: 'Administrator', value: 'administrator' },
              { text: 'Manager', value: 'manager' },
              { text: 'Servicemedewerker', value: 'service' },
              { text: 'Scan- en kassamedewerker', value: 'scanner' },
            ],
            locations: [],
            events: [],
          }
      },
      computed: {
        locationsOptions() {
          let obj = [];
          this.locations.forEach(
            item => {
              obj.push({ value: item.id, text: item.name });
            }
          );

          return obj;
        },
        eventsOptions() {
          let obj = [];
          this.events.forEach(
            item => {
              if (this.form.locations.find( loc => loc == item.location_id)) {
                obj.push({ value: item.id, text: item.name });
              }
            }
          );

          return obj;
        }
      },
      methods: {
        ...mapActions(useShopStore, ['setLoading']),
        toggleInfo: function() {
          if (this.showExplainRoles) {
            this.showExplainRoles = false;
          } else {
            this.showExplainRoles = true;
          }
        },
        getLocations: function() {
          this.setLoading(true);
          this.$axios.get("https://api.tixgo.nl/locations")
            .then( response => {
              this.locations = response.data;
              this.setLoading(false);
          }).catch(response => { console.log(response); });
        },
        getEvents: function() {
          this.setLoading(true);
          this.$axios.get("https://api.tixgo.nl/events")
            .then( response => {
              this.events = response.data;
              this.setLoading(false);
          }).catch(response => { console.log(response); });
        },
        deleteMember: function() {
          let id = this.$route.params.id;
          if( confirm("Weet je zeker dat je dit teamlid wilt verwijderen?") ) {
              this.$axios.delete("https://api.tixgo.nl/teams/" + id)
                .then( response => {
                  response;
                  this.$router.push('/manage/organisation/team')
                }
              );
          }
        },
        getMember: function() {
          this.setLoading(true);
          let id = this.$route.params.id;
          this.$axios.get("https://api.tixgo.nl/teams/" + id)
            .then( response => {
              this.setLoading(false);
              this.form = response.data;
              this.form.is_send_email = true;
            }
          );
        },
        filterEvents: function() {
          // To be improved;
          this.form.events = [];
        },
        async saveMember() {

          let check = await this.$refs['form'].validate();

          if (!check) {
            window.scrollTo({
              top: 0,
              behavior: "smooth"
            });
            
            return;
          }

          let id = this.$route.params.id;
          this.$axios.put("https://api.tixgo.nl/teams/" + id, this.form)
            .then( response => {
              response;
              this.$router.push('/manage/organisation/team')
            }
          );
        }
      },
      created() {
        this.getMember();
        this.getLocations();
        this.getEvents();

        const isUnique = value =>
          new Promise(resolve => {
            this.$axios.get("https://api.tixgo.nl/teams?filter[username]=" + value)
            .then( response => {
              if (response.data.length == 0) {
                return resolve({
                  valid: true
                });
              }

              let item = response.data[0];

              // User can still save same username, but not of someone else.
              if (this.form.id == item.id && item.username === value) {
                return resolve({
                  valid: true
                });
              }

              return resolve({
                valid: false,
                data: {
                  message: `${value} is already taken.`
                }
              });
            }).catch(response => { console.log(response); });
          });

        extend("unique", {
          validate: isUnique,
          message: 'Dit e-mailadres is reeds in gebruik.',
          getMessage: (field, params, data) => data.message
        });
      }
  }
</script>